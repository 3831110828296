import { Dom } from '@yalovich/dom';
import axios from 'axios';
const dom = new Dom(true);
const inputBar = dom.elm('#newsletter-subscribe');
const button = dom.elm('#newsletter button');
const alertMsg = dom.elm("#newsletter-email-alert");
const successMsg = dom.elm("#newsletter-email-success");
const failureMsg = dom.elm("#newsletter-email-failure");

// const KlaviyoClient = new Klaviyo({
//     publicToken: 'Yv3UxQ',
//     privateToken: ''
// });

const validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const submit = () => {

    dom.hide(alertMsg);
    if(!validateEmail(inputBar.value)) return dom.show("#newsletter-email-alert");


//     curl -X POST "https://faas-fra1-afec6ce7.doserverless.co/api/v1/namespaces/fn-adb48988-bf0e-42a2-9bf7-a3b47eb73ed3/actions/submit-lead?blocking=true&result=true" \
//   -H "Content-Type: application/json" \
//   -H "Authorization: "

    axios({
        method: "GET",
        url: `https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-adb48988-bf0e-42a2-9bf7-a3b47eb73ed3/default/submit-lead?email=${inputBar.value}`
    }).then(() => {
        inputBar.value = '';
        dom.show(successMsg);
    }).catch(() => {
        dom.show(failureMsg);
    })

    // const options = {
    //     method: 'GET',
    //     // url: 'https://a.klaviyo.com/api/v2/list/WWz4aC/members',
    //     url: 'https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-adb48988-bf0e-42a2-9bf7-a3b47eb73ed3/default/submit-lead',
    //     headers: {
    //         Accept: 'application/json', 'Content-Type': 'application/json',
    //     },
    //     data: {
    //         profiles: [{email: inputBar.value}]
    //     }
    // };

    // axios.request(options).then(function (response) {
    //     console.log("YES")
    //     console.log(response.data);
    // }).catch(function (error) {
    //     console.error(error);
    // });

    console.log("ok");
}

dom.addListener(button, 'click', submit);
dom.addListener(inputBar, 'keyup', event => {

    if(event.code === 'Enter') submit();

})

console.log("1")